import api_link from "../../config/variables";

export default {
    namespaced: true,
    state() {
        return {
            salutations: [],
            loginName: null
        };
    },
    mutations: {
        getSalutations(state, payload) {
            state.salutations = payload
        },
        getLoginName(state, payload) {
            state.loginName = payload
        },
        getEmail(state, payload) {
            state.email = payload
        }
    },
    actions: {
        async getSalutations(context) {
            const getSalutations = await fetch(api_link + '/salutations', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const responseSalutations = await getSalutations.json();
            if (!getSalutations.ok) {
                const error = new Error(responseSalutations.message || 'Anreden konnten nicht geladen werden');
                throw error;
            }

            const salutations = [];
            for (const key in responseSalutations) {
                const salutation = {
                    genderId: responseSalutations[key].genderId,
                    name: responseSalutations[key].name,
                    salutation: responseSalutations[key].salutation
                };
                salutations.push(salutation);
            }
            context.commit('getSalutations', salutations)
        },
        async register(_, payload) {
            const token = this.getters["auth/token"]
            const response = await fetch(api_link + '/users', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    gender: payload.gender,
                    firstName: payload.firstName,
                    lastName: payload.lastName,
                    loginName: payload.loginName,
                    email: payload.email,
                    password: payload.password
                })
            });
            if (!response.ok) {
                const responseRegister = response.json();
                const error = new Error(responseRegister.message || 'Registrierung fehlgeschlagen!');
                throw error;
            }
            if (response.ok) {
                alert("Benutzer erfolgreich registriert.")
            }
        },
        async checkLoginName(context, payload) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/users?loginName=' + payload.loginName, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json'
                }
            });

            const responseCheckLoginName = await response.json()
            if (!response.ok) {
                const error = new Error(responseCheckLoginName.message || 'Login Name konnte nicht überprüft werden')
                throw error;
            }
            context.commit('getLoginName', responseCheckLoginName)
        },
        async checkEmail(context, payload) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/users?email=' + payload.email, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json'
                }
            });

            const responseEmail = await response.json()
            if (!response.ok) {
                const error = new Error(responseEmail.message || 'Email konnte nicht überprüft werden');
                throw error;
            }
            context.commit('getEmail', responseEmail)
        }
    },
    getters: {
        salutations(state) {
            return state.salutations
        },
        loginName(state) {
            return state.loginName
        },
        email(state) {
            return state.email
        }
    }
}