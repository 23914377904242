<template>
  <div class="h-100">
    <nav class="navbar navbar-expand h-100 d-flex align-items-start">
      <div class="container-fluid d-flex flex-column px-0">
        <div
          :class="[]"
          class="collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul
            :class="[
              !toggle && windowWidth <= 1199 && windowWidth > 500
                ? 'toggle-nav-sm'
                : '',
              !toggle && windowWidth <= 500 ? 'toggle-nav-xs' : '',
              !toggle && windowWidth >= 1200 ? 'toggle-nav-lg' : '',
            ]"
            class="nav-width navbar-nav me-auto mb-2 mb-lg-0 d-block"
          >
            <li class="nav-item">
              <router-link
                class="nav-link"
                aria-current="page"
                to="/pages/start"
                ><font-awesome-icon icon="fa-solid fa-house" /><span
                  class="label"
                  >Start</span
                ></router-link
              >
            </li>
            <li class="nav-item" v-if="this.isAdmin">
              <router-link class="nav-link" to="/pages/users"
                ><font-awesome-icon icon="fa-solid fa-user" /><span
                  class="label"
                  >Benutzer</span
                ></router-link
              >
            </li>
            <li class="nav-item" v-if="this.isAdmin">
              <router-link class="nav-link" to="/pages/permissions"
                ><font-awesome-icon icon="fa-solid fa-lock" /><span
                  class="label"
                  >Berechtigungen</span
                ></router-link
              >
            </li>
            <li class="nav-item" v-if="this.isAdmin">
              <router-link class="nav-link" to="/pages/groups"
                ><font-awesome-icon icon="fa-solid fa-user-group" /><span
                  class="label"
                  >Gruppen</span
                ></router-link
              >
            </li>
            <li class="nav-item" v-if="this.isAdmin || this.hasFileListPermission">
              <router-link class="nav-link" to="/pages/files"
                ><font-awesome-icon icon="fa-solid fa-file" /><span
                  class="label"
                  >Dateien</span
                ></router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/pages/tree-view"
                ><font-awesome-icon icon="fa-solid fa-folder" /><span
                  class="label"
                  >Ordner</span
                ></router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/pages/carts"
                ><font-awesome-icon icon="fa-solid fa-bag-shopping" /><span
                  class="label"
                  >Download</span
                ></router-link
              >
            </li>
            <li class="nav-item" v-if="this.isAdmin">
              <router-link class="nav-link" to="/pages/protocol"
                ><font-awesome-icon icon="fa-solid fa-clipboard-list" /><span
                  class="label"
                  >Protokoll</span
                ></router-link
              >
            </li>
            <li class="nav-item" v-if="this.isAdmin">
              <router-link class="nav-link" to="/pages/register"
                ><font-awesome-icon icon="fa-solid fa-user-plus" /><span
                  class="label"
                  >Registrierung</span
                ></router-link
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://fgg-weser.de/impressum"
                >Impressum</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://fgg-weser.de/impressum/haftungsausschluss"
                >Haftungsausschluss</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://fgg-weser.de/datenschutz"
                >Datenschutz</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      isAdmin: false,
      hasFileListPermission: false
    };
  },
  computed: {
    toggle() {
      return this.$store.getters["toggles/getSidebar"];
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  async created() {
    if ((await this.$store.getters["auth/isAdmin"]) == 1) {
      this.isAdmin = true;
    }
    if ((await this.$store.getters["auth/hasFileListPermission"]) == 1) {
      this.hasFileListPermission = true;
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.navbar-nav {
  width: 180px;
}
.nav-link {
  padding-left: 15px !important;
  font-size: 13px;
  color: #4d8c9c;
}
a.router-link-active {
  background-color: #4d8c9c;
  color: #fff;
}
a svg {
  width: 16px;
  margin-right: 18px;
}

.nav-width {
  width: 180px;
  overflow: hidden;
}
.nav-width .label {
  white-space: nowrap;
}
@media (max-width: 1199px) {
  .nav-width {
    width: 52px;
  }
  .nav-width .label {
    display: none;
  }
}
@media (max-width: 500px) {
  .nav-width {
    width: 0px;
  }
}

.toggle-nav-lg {
  animation: toggle-nav-lg 0.8s;
  width: 52px;
}
.toggle-nav-lg .label {
  display: none;
}
.toggle-nav-sm {
  animation: toggle-nav-sm 0.8s;
  width: 180px;
}
.toggle-nav-xs {
  animation: toggle-nav-xs 0.8s;
  width: 180px;
}

.toggle-nav-xs .label,
.toggle-nav-sm .label {
  display: inline;
}

@keyframes toggle-nav-lg {
  0% {
    width: 180px;
  }
  100% {
    width: 52px;
  }
}
@keyframes toggle-nav-sm {
  0% {
    width: 52px;
  }
  100% {
    width: 180px;
  }
}
@keyframes toggle-nav-xs {
  0% {
    width: 0px;
  }
  100% {
    width: 180px;
  }
}
</style>