import api_link from '../../config/variables.js';

export default {
    namespaced: true,
    state() {
        return {
            protocols: []
        }
    },
    data: {
        timestamp: ""
    },
    methods: {
        getTimestamp: function() {
            const today = newDate();
                const date = (today.getFullYear()-1) + '-' + (today.getMonth()+1) + '-' + today.getDate();
                const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                const dateTime = date + 'T' + time;
                this.timestamp = dateTime;
        }
    },
    mutations: {
        getProtocols(state, payload) {
            state.protocols = payload
        }
    },
    actions: {
        async getProtocols(context) {
            const token = this.getters["auth/token"];
            const getProtocols = await fetch(api_link + '/protocol?startDate=2021-10-24T11:55:32.138', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
            });
            const responseProtocols = await getProtocols.json();
            if (!getProtocols.ok) {
                const error = new Error(responseProtocols.message || 'Protokolle konnten nicht geladen werden');
                throw Error;
            }

            const protocols = [];
            for (const key in responseProtocols) {
                const protocol = {
                    protocolId: responseProtocols[key].protocolId,
                    objectId: responseProtocols[key].objectId,
                    objectName: responseProtocols[key].objectName,
                    inserted: responseProtocols[key].inserted,
                    year: responseProtocols[key].year,
                    month: responseProtocols[key].month,
                    name: responseProtocols[key].name,
                    column: responseProtocols[key].column,
                    oldValue: responseProtocols[key].oldValue,
                    newValue: responseProtocols[key].newValue
                };
                protocols.push(protocol)
            }
            context.commit('getProtocols', protocols)
        }
    },
    getters: {
        protocols(state) {
            return state.protocols
        }
    }
}