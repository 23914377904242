import router from '../../router.js';
import api_link from '../../config/variables.js';

export default {
    namespaced: true,
    state() {
        return {
            hasFileListPermission: null,
            hasPrivateFolderPermission: null,
            hasSendUploadMails: null,
            isAdmin: null,
            token: null,
            timer: null,
            userId: null
        };
    },
    mutations: {
        timer(state, payload) {
            state.timer = payload
        },
        setUser(state, payload) {
            state.token = payload.token;
            state.userId = payload.userId;
            state.hasFileListPermission = payload.hasFileListPermission;
            state.hasPrivateFolderPermission = payload.hasPrivateFolderPermission;
            state.hasSendUploadMails = payload.hasSendUploadMails
            state.isAdmin = payload.isAdmin;
        },
        logout(state) {
            state.token = null;
        },
    },
    actions: {
        logout(context) {
            localStorage.clear();
            context.commit('logout');
        },
        async login(context, payload) {
            //await führt den folgenden Code erst aus, wenn der Aufruf erfolgt und eine Antwort angekommen ist.
            const response = await fetch(api_link + '/user/authenticate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    loginName: payload.loginName,
                    password: payload.password
                })
            });

            const responseLogin = await response.json();
            if (!response.ok) {
                const error = new Error('Benutzername und Passwort stimmen nicht überein')
                throw error;
            }

            const expiresIn = +(responseLogin.exp - responseLogin.iat) * 1000;

            localStorage.setItem('expiresTime', expiresIn)
            localStorage.setItem('loginTime', Number(Date.now()))

            context.commit('setUser', {
                hasFileListPermission: responseLogin.hasFileListPermission,
                hasPrivateFolderPermission: responseLogin.hasPrivateFolderPermission,
                hasSendUploadMails: responseLogin.hasSendUploadMails,
                isAdmin: responseLogin.isAdmin,
                token: responseLogin.token,
                userId: responseLogin.userId
            });
            if (responseLogin['token'] != null) {
                router.push('/pages/start');
            }
        },
        async resetPassword(_, payload) {
            const response = await fetch(api_link + '/user/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: payload.email
                })
            });

            if (!response.ok) {
                const error = new Error('Fehlgeschlagen')
                throw error;
            }
        },
        async changePassword(_, payload) {
            await fetch(api_link + '/users/' + payload.userId + '/password', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    newPassword: payload.newPassword,
                    token: payload.token
                })
            })
        }
    },

    getters: {
        timer(state) {
            return state.timer
        },
        token(state) {
            return { token: state.token }
        },
        hasPrivateFolderPermission(state) {
            return state.hasPrivateFolderPermission
        },
        isAuthenticated(state) {
            return !!state.token;
        },
        isAdmin(state) {
            return state.isAdmin
        },
        user(state) {
            return state.userId;
        },
        hasFileListPermission(state) {
            return state.hasFileListPermission;
        },
        hasSendUploadMails(state) {
            return state.hasSendUploadMails;
        }
    }
}