<template>
  <div>
    <the-header></the-header>
    <div class="d-flex">
      <div class="fixed-left navbar">
        <the-sidebar></the-sidebar>
      </div>
      <div class="mx-5 w-100">
        <div class="content-top clearfix mb-3">
          <h1 class="al-title text-uppercase">Start</h1>
          <ul class="breadcrumb float-end">
            <li class="breadcrumb-item">
              <router-link to="/pages/start">Home</router-link>
            </li>
            <li class="breadcrumb-item active">Start</li>
          </ul>
        </div>
        <div>
          <h1>
            Willkommen im internen Bereich des Internetauftritts der FGG Weser.
          </h1>
          <br />
          <div class="clearfix px-0">
            <div class="col-3 px-0 float-end">
              <img
                style="max-width: 450px; width: 100%"
                alt="Weser"
                src="@/images/bild_startseite.jpg"
              />
            </div>
            <p class="h2">
              Im Weserforum stehen wichtige Dokumente und Sitzungsunterlagen zum
              Download zur Verfügung.<br />
              Für den internen Datenaustausch können Sie gegebenenfalls auch
              selbst Dokumente hochladen.<br /><br />
              Bei Fragen wenden Sie sich bitte an:<br /><br />
              Geschäftsstelle FGG Weser<br />
              An der Scharlake 39<br />
              31135 Hildesheim<br />
              Tel. 05121/509-712<br />
              E-Mail: geschaeftsstelle.weser@nlwkn.niedersachsen.de<br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: false,
    };
  },
  methods: {
    toggleNav(value) {
      this.toggle = value;
    },
  },
};
</script>

<style scoped>
</style>