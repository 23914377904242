import api_link from '../../config/variables.js';

export default {
    namespaced: true,
    state() {
        return {
            permissions: []
        }
    },
    mutations: {
        getPermissions(state, payload) {
            state.permissions = payload
        }
    },
    actions: {
        async getPermissions(context) {
            const token = this.getters["auth/token"]
            const getPermissions = await fetch(api_link + '/permissions', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                }
            });
            const responsePermissions = await getPermissions.json();
            if (!getPermissions.ok) {
                const error = new Error(responsePermissions.message || 'Berechtigungen konnten nicht geladen werden');
                throw error;
            }

            const permissions = [];
            for (const key in responsePermissions) {
                const permission = {
                    userFolderPermissionId: responsePermissions[key].userFolderPermissionId,
                    firstName: responsePermissions[key].firstName,
                    lastName: responsePermissions[key].lastName,
                    folder: responsePermissions[key].folder,
                    path: responsePermissions[key].path,
                    read: responsePermissions[key].read,
                    write: responsePermissions[key].write,
                    delete: responsePermissions[key].delete,
                    groupRead: responsePermissions[key].groupRead,
                    groupWrite: responsePermissions[key].groupWrite,
                    groupDelete: responsePermissions[key].groupDelete,
                    userId: responsePermissions[key].userId
                };
                permissions.push(permission);
            }
            context.commit('getPermissions', permissions)
        },
        async putPermissions(_, rowData) {
            const token = this.getters["auth/token"];
            const merged = Object.assign(rowData.oldData, rowData.newData);
            await fetch(api_link + '/permissions/' + rowData.key, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    read: merged.read,
                    write: merged.write,
                    delete: merged.delete,
                })
            })
        }
    },
    getters: {
        permissions(state) {
            return state.permissions
        }
    }
}