export default {
    namespaced: true,
    state() {
        return {
            sidebarToggle: true
        }
    },
    mutations: {
        setSidebar(state, payload) {
            state.sidebarToggle = payload
        }
    },
    actions: {
        async setSidebar(context, payload) {

            const toggle = payload.toggleValue;
            context.commit('setSidebar', toggle)
        }
    },
    getters: {
        getSidebar(state) {
            return state.sidebarToggle
        }
    }
}