import axios from 'axios';
import api_link from '../../config/variables.js';
import store from '../index.js';

export default {
    namespaced: true,
    state() {
        return {
            folders: [],
            FolderCartId: null,
            userPermissions: null,
            groupPermissions: null,
            activeGroupPermissionsForFolders: null,
            DownloadCartId: null,
            downloads: null,
            uploadPercentage: null,
            loadedMbUpload: null,
            totalMbUpload: null,
            downloadPercentage: null,
            loadedMbDownload: null,
            totalMbDownload: null,
            expandedFolderId: null,
            expandedParentFolderId: null,
        }
    },
    mutations: {
        getFolders(state, payload) {
            state.folders = payload
        },
        getFolderCartId(state, payload) {
            state.FolderCartId = payload
        },
        getUserPermissions(state, payload) {
            state.userPermissions = payload
        },
        getGroupPermissions(state, payload) {
            state.groupPermissions = payload
        },
        getActiveGroupPermissions(state, payload) {
            state.activeGroupPermissionsForFolder = payload
        },
        getDownloadCartId(state, payload) {
            state.DownloadCartId = payload
        },
        getDownloads(state, payload) {
            state.downloads = payload
        },
        getUploadPercentage(state, payload) {
            state.uploadPercentage = payload
        },
        getLoadedMbUpload(state, payload) {
            state.loadedMb = payload
        },
        getTotalMbUpload(state, payload) {
            state.totalMb = payload
        },
        getDownloadPercentage(state, payload) {
            state.onDownloadProgress = payload
        },
        getLoadedMbDownload(state, payload) {
            state.loadedMbDownload = payload
        },
        getTotalMbDownload(state, payload) {
            state.totalMbDownload = payload
        },
        changeExpandedFolderId(state, payload) {
            state.expandedFolderId = payload
        },
        changeExpandedParentFolderId(state, payload) {
            state.expandedParentFolderId = payload
        }
    },
    actions: {
        async getFolders(context) {
            const token = this.getters["auth/token"]
            const userId = this.getters["auth/user"]
            const expandedFolderId = this.getters["tree/expandedFolderId"]
            const expandedParentFolderId = this.getters["tree/expandedParentFolderId"]
            const getFolders = await fetch(api_link + '/folders/1/flattree', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                }
            });
            const responseFolders = await getFolders.json();
            if (!getFolders.ok) {
                const error = new Error(responseFolders.message || 'Ordner konnten nicht geladen werden');
                throw error;
            }
            const folders = [];
            for (const key in responseFolders) {
                const folder = {
                    userId: responseFolders[key].userId,
                    userName: responseFolders[key].userName,
                    id: responseFolders[key].id,
                    parentFolderId: responseFolders[key].parentFolderId,
                    folderTypeId: responseFolders[key].folderTypeId,
                    allId: ((responseFolders[key].folderTypeId == null) ? 'f'.concat(responseFolders[key].id) : responseFolders[key].id),
                    allParentFolderId: ((responseFolders[key].id == responseFolders[key].parentFolderId) ? 0 : ((responseFolders[key].folderTypeId === 3 && responseFolders[key].parentFolderId === 1) ? "e1" : responseFolders[key].parentFolderId)),
                    name: responseFolders[key].name,
                    folderType: responseFolders[key].folderType,
                    size: responseFolders[key].size,
                    uploaded: responseFolders[key].uploaded,
                    read: responseFolders[key].read,
                    write: responseFolders[key].write,
                    delete: responseFolders[key].delete,
                    userRead: responseFolders[key].userRead,
                    userWrite: responseFolders[key].userWrite,
                    userDelete: responseFolders[key].userDelete,
                    isFolder: responseFolders[key].isFolder,
                    isOwner: responseFolders[key].isOwner,
                    expanded: (((responseFolders[key].id == 1 || responseFolders[key].id == expandedFolderId || responseFolders[key].id == expandedParentFolderId) && responseFolders[key].isFolder == 1) ? true : false),
                    html: ((responseFolders[key].folderTypeId == null) ? '<img src=' + require('@/images/file.svg') + ' class="dx-icon"><span>' + responseFolders[key].name + '</span><span class="file-metadata">[Hochgeladen: ' + responseFolders[key].uploaded + ', Größe: ' + (responseFolders[key].size / 1048576).toFixed(2) + ' MB, Benutzer: ' + responseFolders[key].userName + ']</span>' : '<img src=' + require('@/images/folder.svg') + ' class="dx-icon"><span>' + responseFolders[key].name + '</span>')
                };
                folders.push(folder);
            }

            if (this.getters["auth/hasPrivateFolderPermission"] === 1) {
                const userFolder = {
                    id: 1,
                    parentFolderId: 0,
                    folderTypeId: 3,
                    expanded: true,
                    isFolder: 1,
                    allId: "e1",
                    allParentFolderId: 0,
                    html: '<img src=' + require('@/images/folder.svg') + ' class="dx-icon"><span>Eigene Verzeichnisse</span>'
                }
                folders.push(userFolder);
            }

            context.commit('getFolders', folders)

            const getFolderCartId = await fetch(api_link + '/users/' + userId + '/carts', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                }
            });
            const responseFolderCartId = await getFolderCartId.json();
            if (!getFolderCartId.ok) {
                const error = new Error(responseFolderCartId.message || 'Ordner konnten nicht geladen werden');
                throw error;
            }
            context.commit('getFolderCartId', responseFolderCartId[0].cartId)
            context.commit('getDownloadCartId', responseFolderCartId[0].cartId)
        },
        async editTreeItem(_, payload) {

            const token = this.getters["auth/token"]
            var itemId = payload.id
            if (payload.type === "files") {
                payload.delete = false
                payload.read = false
                payload.write = false
            }

            await fetch(api_link + '/' + payload.type + '/' + itemId, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    applyForChildren: payload.applyForChildren,
                    delete: payload.delete,
                    name: payload.name,
                    read: payload.read,
                    write: payload.write
                })
            });
            function applyPermissionsForChildren(hasChildren, read, write, deleteP) {
                hasChildren.children.forEach(child => {
                    if (child.itemData.isFolder == 1) {
                        fetch(api_link + '/folders/' + child.itemData.id, {
                            method: 'PUT',
                            headers: {
                                'Authorization': 'Bearer ' + token.token,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                delete: deleteP,
                                read: read,
                                write: write
                            })
                        });
                        if (child.children.length > 0) {
                            applyPermissionsForChildren(child, read, write, deleteP);
                        }
                    }
                });
            }
            if (payload.applyForChildren == true) {
                applyPermissionsForChildren(payload.hasChildren, payload.read, payload.write, payload.delete)
            }

        },
        async createFolder(context, payload) {
            const token = this.getters["auth/token"]
            const getFolders = await fetch(api_link + '/folders', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    applyForChildren: "false",
                    delete: payload.delete,
                    folderTypeId: payload.folderTypeId,
                    name: payload.name,
                    parentFolderId: payload.parentFolderId,
                    read: payload.read,
                    userId: this.getters["auth/user"],
                    write: payload.write
                })
            });
            const responseFolders = await getFolders.json();
            if (!getFolders.ok) {
                const error = new Error(responseFolders.message || 'Ordner konnten nicht angelegt werden');
                throw error;
            }

            context.commit('changeExpandedFolderId', payload.parentFolderId)
        },
        async uploadFiles(context, payload) {
            const token = this.getters["auth/token"]
            const userId = this.getters["auth/user"]
            const hasSendUploadMails = this.getters["auth/hasSendUploadMails"]
            const formData = new FormData();

            formData.append('userId', userId);
            formData.append('folderId', payload.folderId);
            formData.append('sendUploadMails', hasSendUploadMails)
            for (let i = 0; i < payload.files.length; i++) {
                formData.append('file[]', payload.files.item(i));
            }

            const getFolders = await axios.post(api_link + '/files/upload', formData, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: function (progressEvent) {
                    const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    const loadedMbUpload = (progressEvent.loaded / 1048576).toFixed(2);
                    const totalMbUpload = (progressEvent.total / 1048576).toFixed(2);
                    context.commit('getUploadPercentage', uploadPercentage)
                    context.commit('getLoadedMbUpload', loadedMbUpload)
                    context.commit('getTotalMbUpload', totalMbUpload)
                }.bind(this)
            }).catch(function () {
                const responseGetFolders = getFolders.json();
                const error = new Error(responseGetFolders.message || 'Datei konnte nicht hochgeladen werden!');
                throw error;
            });

            context.commit("changeExpandedFolderId", payload.expandedFolderId)
        },
        async userPermissions(context, payload) {
            const token = this.getters["auth/token"]
            const getUserPermissions = await fetch(api_link + '/folders/' + payload.id + "/permissions", {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
            });
            const responseGetUserPermissions = await getUserPermissions.json();
            if (!getUserPermissions.ok) {
                const error = new Error(responseGetUserPermissions.message || 'Ordner konnten nicht geladen werden');
                throw error;
            }
            context.commit('getUserPermissions', responseGetUserPermissions)
        },
        async safeUserPermission(_, payload) {
            const token = this.getters["auth/token"]
            await fetch(api_link + '/permissions/' + payload.userFolderPermissionId, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    delete: payload.delete,
                    read: payload.read,
                    write: payload.write,
                }),
            });

            function applyPermissionsForChildren(hasChildren, userPermissions, read, write, deleteP) {
                hasChildren.children.forEach(child => {
                    const userFolderPermissionId = userPermissions.find(userPermission => userPermission.folderId === child.itemData.id).userFolderPermissionId
                    if (child.itemData.isFolder === 1) {
                        fetch(api_link + '/permissions/' + userFolderPermissionId, {
                            method: 'PUT',
                            headers: {
                                'Authorization': 'Bearer ' + token.token,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                delete: deleteP,
                                read: read,
                                write: write
                            })
                        });
                        if (child.children.length > 0) {
                            applyPermissionsForChildren(child, userPermissions, read, write, deleteP);
                        }
                    }
                });
            }

            if (payload.applyForChildren) {
                const userPermissionsForAllFolders = await fetch(api_link + '/users/' + payload.selectedUserId + '/permissions', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token.token,
                        'Content-Type': 'application/json',
                    },
                });
                const responseUserPermissionsForAllFolders = await userPermissionsForAllFolders.json();
                applyPermissionsForChildren(payload.hasChildren, responseUserPermissionsForAllFolders, payload.read, payload.write, payload.delete)
            }
        },
        async groupPermissions(context, payload) {
            const token = this.getters["auth/token"]
            const safeUserPermissions = await fetch(api_link + '/folders/' + payload.id + '/group-permissions', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
            });
            const responseSafeUserPermissions = await safeUserPermissions.json();
            if (!safeUserPermissions.ok) {
                const error = new Error(responseSafeUserPermissions.message || 'Ordner konnten nicht geladen werden');
                throw error;
            }

            context.commit("getGroupPermissions", responseSafeUserPermissions)
        },
        async allGroupsForFolder(context, payload) {
            const token = this.getters["auth/token"]
            const allGroupsForFolder = await fetch(api_link + '/active-group-permissions?folderid=' + payload.folderId, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
            });
            const responseAllGroupsForFolder = await allGroupsForFolder.json();
            if (!allGroupsForFolder.ok) {
                const error = new Error(responseAllGroupsForFolder.message || 'Bereits zugeordnete Gruppen konnten nicht geladen werden');
                throw error;
            }

            var assignedGroups = null
            if (responseAllGroupsForFolder.length == 0) {
                assignedGroups = "Keine";
            } else {
                assignedGroups = responseAllGroupsForFolder.map(row => row.name).join(", ");
            }
            context.commit("getActiveGroupPermissions", assignedGroups)
        },
        async safeGroupPermission(_, payload) {
            const token = this.getters["auth/token"]
            await fetch(api_link + '/group-permissions/' + payload.id, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    delete: payload.delete,
                    read: payload.read,
                    write: payload.write
                })
            });

            function applyPermissionsForChildren(hasChildren, groupPermissions, read, write, deleteP) {
                hasChildren.children.forEach(child => {
                    const groupFolderPermissionId = groupPermissions.find(groupPermission => groupPermission.folderId === child.itemData.id).groupFolderPermissionId
                    if (child.itemData.isFolder === 1) {
                        fetch(api_link + '/group-permissions/' + groupFolderPermissionId, {
                            method: 'PUT',
                            headers: {
                                'Authorization': 'Bearer ' + token.token,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                delete: deleteP,
                                read: read,
                                write: write
                            })
                        });
                        if (child.children.length > 0) {
                            applyPermissionsForChildren(child, groupPermissions, read, write, deleteP);
                        }
                    }
                });
            }

            if (payload.applyForChildren) {
                const groupPermissionsForAllFolders = await fetch(api_link + '/groups/' + payload.selectedGroupId + '/permissions', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token.token,
                        'Content-Type': 'application/json',
                    },
                });
                const responseGroupPermissionsForAllFolders = await groupPermissionsForAllFolders.json();
                applyPermissionsForChildren(payload.hasChildren, responseGroupPermissionsForAllFolders, payload.read, payload.write, payload.delete)
            }
        },
        async addItemToDownload(_, payload) {
            const token = this.getters["auth/token"]
            const FolderCartId = this.getters["tree/DownloadCartId"] // FolderCartId zu DownloadCartId
            var itemId = payload.id
            if (payload.type === "files") {
                await fetch(api_link + '/carts/' + FolderCartId + '/' + payload.type, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token.token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        fileId: itemId
                    }),
                });
            } else {
                await fetch(api_link + '/carts/' + FolderCartId + '/' + payload.type, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token.token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        folderId: itemId
                    }),
                });
            }
        },
        async deleteTreeItem(context, payload) {
            const token = this.getters["auth/token"]
            var itemId = payload.id
            if (payload.type === "files") {
                await fetch(api_link + '/files/' + itemId, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + token.token,
                        'Content-Type': 'application/json',
                    }
                });
            } else {
                await fetch(api_link + '/folders/' + itemId, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + token.token,
                        'Content-Type': 'application/json',
                    }
                });
            }
            await context.commit("changeExpandedParentFolderId", payload.expandedParentFolderId);

            await context.commit("changeExpandedFolderId", payload.expandedFolderId);
        },
        async downloadSingleFile(_, payload) {
            var id = payload.id
            const token = this.getters["auth/token"]

            await axios.get(api_link + '/files/' + id + '/download', {
                responseType: 'blob',
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/octet-stream',
                },
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', payload.name);
                document.body.appendChild(link);
                link.click();
            })
        },
        clearUploadPercentage(context) {
            context.commit('getUploadPercentage', 0)
        },
        async changeTree(_, payload) {
            const token = this.getters["auth/token"]
            var itemtype = "files"
            if (payload.fromNode.isFolder == 1) {
                itemtype = "folders"
                await fetch(api_link + '/' + itemtype + '/' + payload.fromNode.id, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + token.token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        parentFolderId: payload.toNode.id
                    })
                });
            } else {
                await fetch(api_link + '/' + itemtype + '/' + payload.fromNode.id, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + token.token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        folderId: payload.toNode.id
                    })
                });
            }
        },

        //Download-Seite
        async getDownload(context) {
            const token = this.getters["auth/token"]
            const userId = this.getters["auth/user"]
            const getCart = await fetch(api_link + '/users/' + userId + '/carts', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                }
            });
            const responseCarts = await getCart.json();
            if (!getCart.ok) {
                const error = new Error(responseCarts.message || 'Der Warenkorb konnte nicht abgefragt werden');
                throw error;
            }
            context.commit('getDownloadCartId', responseCarts[0].cartId)

            const getFolders = await fetch(api_link + '/carts/' + responseCarts[0].cartId + '/flattree', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                }
            });
            const responseFolders = await getFolders.json();
            if (!getFolders.ok) {
                const error = new Error(responseFolders.message || 'Der Warenkorb konnte nicht abgefragt werden');
                throw error;
            }
            const folders = [];
            for (const key in responseFolders) {
                const folder = {
                    id: responseFolders[key].id,
                    parentFolderId: responseFolders[key].parentFolderId,
                    name: responseFolders[key].name,
                    isFolder: responseFolders[key].isFolder,
                    allId: responseFolders[key].allId,
                    allParentFolderId: responseFolders[key].allParentFolderId,
                    html: '<img src=' + ((responseFolders[key].isFolder == 1) ? require('@/images/folder.svg') : require('@/images/file.svg')) + ' class="dx-icon"><span>' + responseFolders[key].name + '</span>'
                }
                folders.push(folder);
            }
            context.commit('getDownloads', folders)
        },
        async getDownloadCartId(context) {
            const token = this.getters["auth/token"]
            const userId = this.getters["auth/user"]
            const getCart = await fetch(api_link + '/users/' + userId + '/carts', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                }
            });
            const responseCarts = await getCart.json();
            if (!getCart.ok) {
                const error = new Error(responseCarts.message || 'Der Warenkorb konnte nicht abgefragt werden');
                throw error;
            }
            context.commit('getDownloadCartId', responseCarts[0].cartId)
        },
        async delteItemFromTree(_, payload) {
            const token = this.getters["auth/token"]
            const cartId = this.getters["tree/DownloadCartId"]

            const deleteItem = await fetch(api_link + '/carts/' + cartId + '/' + payload.type + '/' + payload.id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                }
            });
            const responseDeleteItem = await deleteItem.json();
            if (!deleteItem.ok) {
                const error = new Error(responseDeleteItem.message || 'Item konnte nicht gelöscht werden');
                throw error;
            }
        },
        async downloadAsZip(context) {
            const token = this.getters["auth/token"]
            const cartId = this.getters["tree/DownloadCartId"]
            const date = new Date().toLocaleDateString().replace('.', '_').replace('.', '_');

            const downloadAsZip = await axios.get(api_link + '/carts/' + cartId + '/download', {
                responseType: 'blob',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                },
                onDownloadProgress: function (progressEvent) {
                    const downloadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                    const loadedMbDownload = (progressEvent.loaded / 1048576).toFixed(2);
                    const totalMbDownload = (progressEvent.total / 1048576).toFixed(2);
                    context.commit('getDownloadPercentage', downloadPercentage)
                    context.commit('getLoadedMbDownload', loadedMbDownload)
                    context.commit('getTotalMbDownload', totalMbDownload)
                }.bind(this)
            }).then((response) => {
                const downloadItem = new Blob([response.data])
                const url = window.URL.createObjectURL(downloadItem);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `download_intern_${date}.zip`);
                document.body.appendChild(link);
                link.click();
            })

        },
        async clearCart() {
            const token = this.getters["auth/token"]
            const cartId = this.getters["tree/DownloadCartId"]
            const userId = this.getters["auth/user"]
            //Löscht die Cart
            const deleteCart = await fetch(api_link + '/carts/' + cartId, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                }
            });
            //Legt eine neue Cart an 
            const createCart = await fetch(api_link + '/carts', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: "Standard",
                    userId: userId
                })
            });

            const responseCreateCart = await createCart.json();
            if (!createCart.ok) {
                const error = new Error(responseCreateCart.message || 'Neuer Warenkorb konnte nicht angelegt werden');
                throw error;
            }

        },
        async clearDownloadPercentage(context) {
            await context.commit('getDownloadPercentage', 0)
        },
    },
    getters: {
        folders(state) {
            return state.folders
        },
        FolderCartId(state) {
            return state.FolderCartId
        },
        userPermissions(state) {
            return state.userPermissions
        },
        groupPermissions(state) {
            return state.groupPermissions
        },
        activeGroupPermissionsForFolder(state) {
            return state.activeGroupPermissionsForFolder
        },
        DownloadCartId(state) {
            return state.DownloadCartId
        },
        downloads(state) {
            return state.downloads
        },
        uploadPercentage(state) {
            return state.uploadPercentage
        },
        loadedMbUpload(state) {
            return state.loadedMb
        },
        totalMbUpload(state) {
            return state.totalMb
        },
        downloadPercentage(state) {
            return state.downloadPercentage
        },
        loadedMbDownload(state) {
            return state.loadedMbDownload
        },
        totalMbDownload(state) {
            return state.totalMbDownload
        },
        expandedFolderId(state) {
            return state.expandedFolderId
        },
        expandedParentFolderId(state) {
            return state.expandedParentFolderId
        }
    }
}