<template>
  <nav class="navbar navbar-expand sticky-top" style="z-index: auto;">
        <div class="container-fluid ps-3 pe-0" maxheight="50">
          <router-link class="navbar-brand" to="/pages/start">
            <img src="@/images/logo.png" alt="FGG Weser" class="d-inline-block align-text-top" />
          </router-link>
          <div class="me-auto">
            <button class="btn ms-auto" type="button" @click="toggleMenu">
              <font-awesome-icon icon="fa-solid fa-bars" />
            </button>
          </div>
          <div class="dropdown me-5">
            <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
              <font-awesome-icon icon="fa-solid fa-gear" />
            </button>
            <ul class="dropdown-menu dropdown-menu-lg-end m-0" aria-labelledby="dropdownMenuButton1">

              <li><a class="dropdown-item" @click="profile()"><font-awesome-icon icon="fa-solid fa-user" class="mr-1" />Profil</a></li>
              <li><router-link class="dropdown-item" @click="logout()" to="/" ><font-awesome-icon icon="fa-solid fa-power-off" />Abmelden</router-link></li>
            </ul>
          </div>
        </div>
      </nav>
</template>

<script>
export default {
  data() {
    return {
      toggleValue: null
    }
  },
  methods: {
    toggleMenu() {
      this.toggleValue = !this.toggleValue;
      this.$store.dispatch('toggles/setSidebar', {
          toggleValue: this.toggleValue,
        });
    },
    async profile() {
      await this.$store.dispatch("users/getUsersId");
      // await new Promise(resolve => setTimeout(resolve, 1000));
      await this.$router.push("profile");
    },
    logout() {
      this.$store.dispatch('auth/logout');
    }
  },
  beforeMount() {
    this.toggleValue = this.$store.getters["toggles/getSidebar"];
  }
}
</script>

<style scoped>
.navbar {
  height: 66px;
  box-shadow: 2px 0 3px rgb(0 0 0 / 50%);
}
.btn {
  color: #4d8c9c;
  font-size: 20px;
}
.btn.active,
.btn:hover,
.btn:focus {
  color: #005580;
  border: none;
}
.dropdown-item {
  font-size: 14px;
  color: #4d8c9c;
}
.dropdown-item.active,
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: unset;
  cursor: pointer;
}
#dropdownMenuButton1 {
  border: none;
}
a svg {
  width: 16px;
  margin-right: 10px;
}
</style>