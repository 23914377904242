import { createRouter, createWebHashHistory } from 'vue-router'

import start from './pages/pages/start.vue';
import login from './pages/login/login.vue';


import store from './store/index.js';

const router = createRouter({
  history: createWebHashHistory('/'),
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/:notFound(.*)', redirect: '/login' },
    { path: '/pages/start', component: start, meta: { requiresAuth: true } },
    { path: '/pages/users', component: () => import('./pages/pages/users.vue'), props: true, meta: { requiresAuth: true, requiresAdmin: true } },
    { path: '/pages/permissions', component: () => import('./pages/pages/permissions.vue'), meta: { requiresAuth: true, requiresAdmin: true } },
    { path: '/pages/groups', component: () => import('./pages/pages/groups.vue'), meta: { requiresAuth: true, requiresAdmin: true } },
    { path: '/pages/group/:id', component: () => import('./pages/pages/group.vue'), props: true, meta: { requiresAuth: true, requiresAdmin: true } },
    { path: '/pages/files', component: () => import('./pages/pages/files.vue'), meta: { requiresAuth: true, requiresFilesListPermission: true } },
    { path: '/pages/tree-view', component: () => import('./pages/pages/treeView.vue'), meta: { requiresAuth: true } },
    { path: '/pages/carts', component: () => import('./pages/pages/carts.vue'), meta: { requiresAuth: true } },
    { path: '/pages/protocol', component: () => import('./pages/pages/protocol.vue'), meta: { requiresAuth: true, requiresAdmin: true } },
    { path: '/pages/register', component: () => import('./pages/pages/register.vue'), meta: { requiresAuth: true, requiresAdmin: true } },
    { path: '/pages/profile', component: () => import('./pages/pages/profile.vue'), meta: { requiresAuth: true } },
    { path: '/pages/profile/:id', component: () => import('./pages/pages/profile.vue'), props: true, meta: { requiresAuth: true } },
    { path: '/login', component: login },
    { path: '/password-reset', component: () => import('./pages/resetPassword/resetPassword.vue') },
    { path: '/password-reset-confirmation', component: () => import('./pages/resetPassword/passwordResetConfirmation.vue') },
    { path: '/password-change', component: () => import('./pages/resetPassword/passwordChange.vue') },
  ]
});

router.beforeEach(function (to, from, next) {
  const auth = store.getters["auth/isAuthenticated"];
  const isAdmin = store.getters["auth/isAdmin"];
  const hasFileListPermission = store.getters["auth/hasFileListPermission"];
  if (to.meta.requiresAuth && !auth) {
    next('/login');
  }
  else {
    if (to.meta.requiresAdmin == true) { // Überprüft ob die aufzurufende Seite nur für Admins ist
      if (isAdmin == 1) {  //Prüft die Berechtigung des angemeldeten Benutzer auf die Adminberechtiegung
        next();
      } else {
        router.push(from.path)
      }
    } else if (to.meta.requiresFilesListPermission == true) {
      if (isAdmin == 1 || hasFileListPermission == 1) {  //Prüft die Berechtigung des angemeldeten Benutzer auf die Dateilisten-Berechtigung oder ob man Admin ist
        next();
      } else {
        router.push(from.path)
      }
    } else {
      next();
    }
  }
});

let logoutTimer
router.afterEach(function () {
  if (store.getters["auth/token"].token) {
    if (Number(Date.now()) > +Number(localStorage.getItem('loginTime')) + Number(localStorage.getItem('expiresTime'))) {
      store.dispatch("auth/logout");
    } else {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(
        function () { store.dispatch("auth/logout") },
        localStorage.getItem('expiresTime')
      );
    }
  }
})

export default router
