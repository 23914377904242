<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

export default {

}
</script>

<style>
body {
  font-family: Roboto, sans-serif;
  color: #023245;
}
h1, .h1,
h2, .h2,
h3, .h3 {
  font-weight: 500;
}
h1, .h1 {
  font-size: 32px;
}
h2, .h2 {
  font-size: 24px;
}
h3, .h3 {
  font-size: 20px;
}
h4, .h4 {
  font-size: 18px;
}
p {
  font-size: 14px;
  /* font-size: 24px; */
}
h1.al-title {
  font-weight: 700;
  float: left;
  font-size: 24px;
  text-transform: uppercase;
}
.col-form-label {
  font-size: 13px;
}
.navbar {
  background-color: #f4f4f4;
}
@media(max-width:1200px) {
  p,
  h1,
  h2,
  h3 {
    word-break: break-word;
    line-height: 1.1;
  }
}
.content-top {
  padding-top: 20px;
}
button.btn.btn-default {
  color: #023245;
  border-color: #d6d6d6;
}
button.btn.btn-default:hover {
  border-color: #d6d6d6;
  background-color: #d6d6d6;
}
.breadcrumb-item {
  font-size: 18px;
  line-height: 16px;
}
.breadcrumb-item a {
  color: #82afba;
  text-decoration: none;
}
.card-title {
  font-size: 16px;
  padding-bottom: 0.5rem;
  color: #023245;
}
.form-group label {
  font-size: 13px;
}
.card h3 {
  border-bottom: 1px solid #0000001f;
}
.card {
  border-color: transparent;
  box-shadow: 0 5px 5px 0 rgb(0 0 0 / 25%);
}

.btn.disabled, .btn:disabled {
    cursor: not-allowed;
    opacity: .65;
}
.icon:hover {
  cursor: pointer;
}
.dx-dropdowneditor-icon,
.icon,
.dx-datagrid-content a {
  color: #4d8c9c!important;
}
.form-control:focus {
  box-shadow: none!important;
}
.input-error {
  border: 1px solid #fa758e!important;
}
.input-correct {
  border: 1px solid #a2db59!important;
}
</style>