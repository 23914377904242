<template>
  <main class="ng2">
    <div class="auth-main">
      <div class="auth-block">
        <h1>Anmelden</h1>
        <form @submit.prevent="login">
          <div class="row row-mb-3 p-2">
            <label class="col-sm-2 col-form-label">Benutzername</label>
            <div class="col ms-sm-3">
              <input
                type="text"
                v-model="loginName"
                class="form-control"
                placeholder="Benutzername"
              />
            </div>
          </div>
          <div class="row row-mb-3 p-2">
            <label class="col-sm-2 col-form-label">Passwort</label>
            <div class="col ms-sm-3">
              <input
                type="password"
                v-model="password"
                class="form-control ng-dirty ng-valid ng-touched"
                placeholder="Passwort"
              />
            </div>
          </div>
          <div class="offset-sm-2 col-sm-10 p-2">
            <button
              class="btn btn-default btn-auth ms-sm-3"
              type="submit"
              :disabled="!loginName || !password"
            >
              Anmelden
            </button>
            <router-link class="float-end" to="/password-reset"
              >Passwort vergessen?</router-link
            >
          </div>
        </form>
        <div class="ps-2">
          <a href="https://fgg-weser.de/impressum" style="margin-right: 10px"
            >Impressum</a
          >
          <a
            href="https://fgg-weser.de/impressum/haftungsausschluss"
            style="margin-right: 10px"
            >Haftungsausschluss</a
          >
          <a href="https://fgg-weser.de/datenschutz" style="margin-right: 10px"
            >Datenschutz</a
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      loginName: "",
      password: "",
    };
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("auth/login", {
          loginName: this.loginName,
          password: this.password,
        });
      } catch (error) {
        alert("Benutzername und Passwort stimmen nicht überein");
      }
    },
  },
  created() {
    localStorage.clear();
  },
};
</script>

<style scoped>
.auth-block a {
  font-size: 14px;
}
.auth-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
}
.auth-block {
  width: 600px;
  margin: 0 auto;
  border-radius: 5px;
  background-color: #f4f4f4;
  padding: 32px;
}
.auth-block h1 {
  margin-bottom: 28px;
  text-align: center;
}
.auth-block a {
  text-decoration: none;
  color: #4d8c9c;
}
.auth-block a:hover {
  color: #417785;
}
</style>