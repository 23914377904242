import api_link from '../../config/variables.js';

export default {
    namespaced: true,
    state() {
        return {
            users: [],
            admins: [],
            user: null
        }
    },
    mutations: {
        getUsers(state, payload) {
            state.users = payload
        },
        user(state, payload) {
            state.user = payload
        },
        admins(state, payload) {
            state.admins = payload
        }
    },
    actions: {
        async getUsers(context) {
            const token = this.getters["auth/token"];
            const getUsers = await fetch(api_link + '/users', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
            });
            const responseUsers = await getUsers.json();
            if (!getUsers.ok) {
                const error = new Error(responseUsers.message || 'User konnten nicht geladen werden');
                throw error;
            }

            const users = [];
            for (const key in responseUsers) {
                const user = {
                    userId: responseUsers[key].userId,
                    userStatusId: responseUsers[key].userStatusId,
                    genderId: responseUsers[key].genderId,
                    gender: responseUsers[key].gender,
                    salutation: responseUsers[key].salutation,
                    firstName: responseUsers[key].firstName,
                    lastName: responseUsers[key].lastName,
                    loginName: responseUsers[key].loginName,
                    email: responseUsers[key].email,
                    registered: responseUsers[key].registered,
                    lastLogin: responseUsers[key].lastLogin,
                    admin: responseUsers[key].admin,
                    fileListPermission: responseUsers[key].fileListPermission,
                    privateFolderPermission: responseUsers[key].privateFolderPermission,
                    receiveUploadMails: responseUsers[key].receiveUploadMails,
                    receiveUploadReportMails: responseUsers[key].receiveUploadReportMails,
                    sendUploadMails: responseUsers[key].sendUploadMails,
                    archived: responseUsers[key].archived
                };
                users.push(user);
            }
            context.commit('getUsers', users)
        },
        async getAdmins(context) {
            const token = this.getters["auth/token"];
            const getUsers = await fetch(api_link + '/users', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
            });
            const responseUsers = await getUsers.json();
            if (!getUsers.ok) {
                const error = new Error(responseUsers.message || 'User konnten nicht geladen werden');
                throw error;
            }

            const users = [];
            for (const key in responseUsers) {
                const user = {
                    userId: responseUsers[key].userId,
                    firstName: responseUsers[key].firstName,
                    lastName: responseUsers[key].lastName,
                    loginName: responseUsers[key].loginName,
                    admin: responseUsers[key].admin,
                };
                if (user.admin == 1) {
                    users.push(user);
                }
            }
            context.commit('admins', users)
        },
        async putUsers(_, rowData) {
            const token = this.getters["auth/token"];
            const merged = Object.assign(rowData.oldData, rowData.newData);
            await fetch(api_link + '/users/' + rowData.key, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: merged.userId,
                    userStatusId: merged.userStatusId,
                    genderId: merged.genderId,
                    firstName: merged.firstName,
                    lastName: merged.lastName,
                    email: merged.email,
                    registered: merged.registered,
                    lastLogin: merged.lastLogin,
                    admin: merged.admin,
                    fileListPermission: merged.fileListPermission,
                    privateFolderPermission: merged.privateFolderPermission,
                    receiveUploadMails: merged.receiveUploadMails,
                    receiveUploadReportMails: merged.receiveUploadReportMails,
                    sendUploadMails:merged.sendUploadMails,
                    archived: merged.archived
                })
            })
        },
        async updateProfile(context, payload) {
            const token = this.getters["auth/token"];
            await fetch(api_link + '/users/' + payload.userId, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: payload.userId,
                    userStatusId: payload.userStatusId,
                    genderId: payload.genderId,
                    firstName: payload.firstName,
                    lastName: payload.lastName,
                    email: payload.email,
                    registered: payload.registered,
                    lastLogin: payload.lastLogin,
                    admin: payload.admin,
                    fileListPermission: payload.fileListPermission,
                    privateFolderPermission: payload.privateFolderPermission,
                    receiveUploadMails: payload.receiveUploadMails,
                    receiveUploadReportMails: payload.receiveUploadReportMails,
                    sendUploadMails: payload.sendUploadMails,
                    archived: payload.archived
                })
            });
            context.commit("user", payload)
        },
        async deleteUsers(_, rowData) {
            const token = this.getters["auth/token"];
            await fetch(api_link + '/users/' + rowData.data.userId, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                }
            });
        },
        async getUsersId(context, rowData) {
            const token = this.getters["auth/token"];
            if (rowData == undefined) {
                const getUserId = await fetch(api_link + '/users/' + this.getters["auth/user"], {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token.token,
                        'Content-Type': 'application/json',
                    },
                });
                const responseUser = await getUserId.json();
                
                if (!getUserId.ok) {
                    const error = new Error(responseUser.message || 'Profil konnte nicht geladen werden!');
                    throw error;
                }
                const user = responseUser;
                context.commit("user", user);
            } else {
                const getUserId = await fetch(api_link + '/users/' + rowData.row.key, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token.token,
                        'Content-Type': 'application/json',
                    },
                });
                const responseUser = await getUserId.json();

                if (!getUserId.ok) {
                    const error = new Error(responseUser.message || 'Profil konnte nicht geladen werden!');
                    throw error;
                }
                const user = responseUser;
                context.commit("user", user)
            }
        },
        async updatePassword(_, payload) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/users/' + payload.userId + '/password', {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    newPassword: payload.newPassword,
                    oldPassword: payload.oldPassword
                })
            });

            if (response.ok) {
                alert('Erfolgreich')
            } else {
                alert('Fehlgeschlagen')
            }
        },
        async finalDelete(_, payload) {
            const token = this.getters["auth/token"];
            await fetch(api_link + '/users/' + payload.userId + '?permanent=1&new-user-id=' + payload.newUserId, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json'
                }
            })
        }
    },

    getters: {
        users(state) {
            return state.users
        },
        user(state) {
            return state.user
        },
        admins(state) {
            return state.admins
        }
    }
}