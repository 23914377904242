import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import authenicateModule from './modules/authenicate.js';
import usersModule from './modules/users.js';
import permissionsModule from './modules/permissions.js';
import groupsModule from './modules/groups.js';
import filesModule from './modules/files.js';
import treeViewModule from './modules/treeView.js';
import protocolsModule from './modules/protocols.js';
import registerModule from './modules/register.js';
import toggleModule from './modules/toggle.js';


const store = createStore({
  plugins: [createPersistedState({
    paths: ["auth","toggles","users.user"],
  })],
  modules: {
    auth: authenicateModule,
    users: usersModule,
    perm: permissionsModule,
    groups: groupsModule,
    files: filesModule,
    tree: treeViewModule,
    prot: protocolsModule,
    reg: registerModule,
    toggles: toggleModule
  },
});


export default store;