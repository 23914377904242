import api_link from '../../config/variables.js';

export default {
    namespaced: true,
    state() {
        return {
            groups: [],
            title: null,
            members: []
        }
    },
    mutations: {
        getGroups(state, payload) {
            state.groups = payload
        },
        getGroupMembers(state, payload) {
            state.members = payload
        },
        title(state, payload) {
            state.title = payload
        }
    },
    actions: {
        async getGroups(context) {
            const token = this.getters["auth/token"];
            const getGroups = await fetch(api_link + '/groups', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
            });
            const responseGroups = await getGroups.json();
            if (!getGroups.ok) {
                const error = new Error(responseGroups.message || 'Gruppen konnten nicht geladen werden');
                throw error;
            }

            const groups = [];
            for (const key in responseGroups) {
                const group = {
                    groupId: responseGroups[key].groupId,
                    name: responseGroups[key].name,
                    userCount: responseGroups[key].userCount
                };
                groups.push(group);
            }
            context.commit('getGroups', groups)
        },
        async putGroups(_, rowData) {
            const token = this.getters["auth/token"];
            const merged = Object.assign(rowData.oldData, rowData.newData);
            await fetch(api_link + /groups/ + rowData.key, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: merged.name
                })
            })
        },
        async getGroupMembers(context, rowData) {
            const title = rowData.row.data.name;
            const token = this.getters["auth/token"];
            const getGroupMembers = await fetch(api_link + '/groups/' + rowData.row.key + '/members', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
            });
            const responseMembers = await getGroupMembers.json();

            if (!getGroupMembers.ok) {
                const error = new Error(responseMembers.message || 'Mitglieder konnten nicht geladen werden');
                throw error;
            }
            const members = [];
            for (const key in responseMembers) {
                const member = {
                    userId: responseMembers[key].userId,
                    groupId: responseMembers[key].groupId,
                    userGroupId: responseMembers[key].userGroupId,
                    name: responseMembers[key].name,
                    member: responseMembers[key].member
                };
                members.push(member);
            }
            context.commit('getGroupMembers', members);
            context.commit('title', title);
        },
        async postGroup(_, rowData) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/groups', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: rowData.data.name
                }),
            });

            if (!response.ok) {
                const responseGroup = response.json();
                const error = new Error(responseGroup.message || 'Gruppe konnte nicht erstellt werden!');
                throw error;
            }
        },
        async deleteGroup(_, rowData) {
            const token = this.getters["auth/token"];
            response = await fetch(api_link + '/groups/' + rowData.key, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const responseGroup = response.json();
                const error = new Error(responseGroup.message || 'Gruppe konnte nicht gelöscht werden!');
                throw error;
            }
        },
        async postMember(_, currentRowData) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/groups/' + currentRowData.groupId + '/members', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: currentRowData.userId
                }),
            });

            if (!response.ok) {
                const responseMember = response.json();
                const error = new Error(responseMember.message || 'Mitglied konnte nicht hinzugefügt werden!');
                throw error;
            }
        },
        async deleteMember(_, currentRowData) {
            const token = this.getters["auth/token"];
            reponse = await fetch(api_link + '/groups/' + currentRowData.groupId + '/members/' + currentRowData.userId, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                const responseMember = response.json();
                const error = new Error(responseMember.message || 'Mitglied konnte nicht entfernt werden!');
                throw error;
            }
        }
    },
    getters: {
        groups(state) {
            return state.groups
        },
        members(state) {
            return state.members
        },
        title(state) {
            return state.title
        }
    }
}