import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGear, faHouse, faUser, faLock, faUserGroup, faFile, faFolder, faBagShopping, faClipboardList, faUserPlus, faPowerOff, faArrowRight, faDownload, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

import router from './router'
import store from './store'
import App from './App.vue'
import theSidebar from './components/layout/theSidebar.vue';
import theHeader from './components/layout/theHeader.vue';

library.add(faGear, faHouse, faUser, faLock, faUserGroup, faFile, faFolder, faBagShopping, faClipboardList, faUserPlus, faPowerOff, faArrowRight, faDownload, faBars, faXmark);

const app = createApp(App);

app.use(router);
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('the-sidebar', theSidebar)
app.component('the-header', theHeader)

app.mount('#app')