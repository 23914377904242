import api_link from '../../config/variables.js';

export default {
    namespaced: true,
    state() {
        return {
            files: []
        }
    },
    mutations: {
        getFiles(state, payload) {
            state.files = payload
        }
    },
    actions: {
        async getFiles(context) {
            const token = this.getters["auth/token"];
            const getFiles = await fetch(api_link + '/files', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json',
                }
            });
            const responseFiles = await getFiles.json();
            if (!getFiles.ok) {
                const error = new Error(responseFiles.message || 'Berechtigungen konnten nicht geladen werden');
                throw error;
            }

            const files = [];
            for (const key in responseFiles) {
                const file = {
                    fileId: responseFiles[key].fileId,
                    folderId: responseFiles[key].folderId,
                    user: responseFiles[key].user,
                    guid: responseFiles[key].guid,
                    name: responseFiles[key].name,
                    size: responseFiles[key].size,
                    uploaded: responseFiles[key].uploaded,
                    path: responseFiles[key].path,
                    userId: responseFiles[key].userId,
                    userFile: responseFiles[key].userFile,
                    write: responseFiles[key].write,
                    delete: responseFiles[key].delete
                };
                files.push(file);
            }
            context.commit('getFiles', files)
        },
        async updateFiles(_, rowData) {
            const token = this.getters["auth/token"];
            await fetch(api_link + '/files/' + rowData.oldData.fileId, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: rowData.newData.name
                })
            });
        },
        async deleteFile(_, rowData) {
            const token = this.getters["auth/token"];
            const response = await fetch(api_link + '/files/' + rowData.data.fileId, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token.token,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                const responseFile = response.json();
                const error = new Error(responseFile.message || 'Datei konnte nicht gelöscht werden!');
                throw error;
            }
        }
    },
    getters: {
        files(state) {
            return state.files
        }
    }
}